// splash page

const SplashPage = (() => {
    const data = $('.js-splash-page-wrapper').data('data');
    const countrySelector = $('.js-splash-country-selector');
    const localeSelector = $('.js-splash-locale-selector');
    const continueButton = $('.js-splash-continue-button');
    const defaultFlagClass = 'flag-icon';

    function updateFlag(elementClass, flagClass, newValue) {
        $(`.${elementClass}`).removeClass().addClass(`${elementClass} ${flagClass} flag-icon-${newValue}`);
    }

    /**
     * @description Function for pushing data_ready event on splash page load
     */
    function handleSplashPageLoadGtm() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ page: null });
        window.dataLayer.push({
            event: 'data_ready',
            page: {
                page_type: 'country_selection',
                page_country: 'undefined',
                page_language: 'undefined',
            },
            traffictype: {
                platform: 'web',
                ip_address: $('.js-splash-page-wrapper').data('ip'),
            },
        });
    }

    return {
        init() {
            if (data.length === 0 || countrySelector.length === 0) {
                return; // quit early, idk what comes after this tbh
            }

            // browser back hack
            setTimeout(() => {
                countrySelector.change();
                localeSelector.change();
            }, 100);

            // when country dropdown changes
            countrySelector.on('change', (e) => {
                e.preventDefault();

                const $this = $(e.target);
                // get locales of the selected country
                const locales = data[$this.val()];

                // clear existing list
                localeSelector.html('');

                // popuplate new list
                $.each(locales, (key, val) => {
                    localeSelector.append(new Option(val.value, val.id));
                });

                // select first option as selected
                localeSelector.prop('selectedIndex', 0).change();

                // update country flag
                updateFlag('js-country-icon', defaultFlagClass, $this.val().toLowerCase());
            });

            // when locale dropdown changes
            localeSelector.on('change', (e) => {
                e.preventDefault();

                // get selected locale
                const locale = localeSelector.val().replace('_', '-').toLowerCase();
                const url = continueButton.attr('href');

                // why? because it also matches with bas'ic-fi't
                // remove 'top-navigation' from url
                const newURL = url.replace(/\/[a-z]{2}-[a-z]{2}\/?/, `/${locale}/`).slice(0, 7) + 'home';

                // update url
                continueButton.attr('href', newURL);

                // update language flag
                updateFlag('js-lang-icon', defaultFlagClass, locale.split('-')[0]);
            });

            handleSplashPageLoadGtm();

        },
    };
})();

export default (() => {
    $(document).ready(SplashPage.init);
})();
